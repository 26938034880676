import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link, useParams } from "react-router-dom";
// import ReactPlayer from 'react-player';
import img9 from "../assets/img/9.png";

import ResolucionesListing from "./ResolucionesListing";
import normativasData from "../normativas-resoluciones.json";

//CONTEXT
import ConexContext from "../context/ConexContext";

export const Resoluciones = () => {
	const { lang } = useParams(); //parametros de la url
	const { loc } = useContext(ConexContext); //contexto
	const location = useLocation();

	useEffect(() => {
		document.title = "Resoluciones CFID";
	}, []);

	useEffect(() => {
		if (parseInt(location.hash.slice(1)) >= 0){
			const index = parseInt(location.hash.slice(1), 10); // Obtiene el índice
			setEstadoAcordeones((prevState) => 
				prevState.map((value, i) => i === index ? true : false)
			);
		}
	// eslint-disable-next-line
	}, [location]);

	const [estadoAcordeones, setEstadoAcordeones] = useState([false, false]); // Arreglo para almacenar el estado de los acordeones (cerrado = false)

    // Función para alternar el estado de un acordeón específico
    const toggleAcordeon = (index) => {
        setEstadoAcordeones((prevEstado) => {
            const nuevosEstados = [...prevEstado];
            nuevosEstados[index] = !nuevosEstados[index];
            return nuevosEstados;
        });
    };

	return (
		<>
			<div className="empresas resoluciones">
				<div className="col-12 volver">
					{/* <div className='col-12 contvolver'>
						<Link to={{pathname:`/${lang}/inicio`}} alt="volver al inicio">
							{loc("Volver")}
						</Link>
					</div> */}
					<a href="#main-content" className="oculto">
						Saltar al contenido principal
					</a>
				</div>
				<div className="col-12 contslider">
					<div className="col-12 info">
						<div className="col-6">
							<img src={img9} alt="EdPuzzle" className="sliderimg" />
						</div>
						<div className="col-6">
							<h1>{loc("RESOLUCIONES-TITULO")}</h1>
							<p>{loc("RESOLUCIONES-SUBTITULO")}</p>
						</div>
					</div>
				</div>
			</div>

			<nav aria-label="breadcrumb" className="migapan rojo">
				<ol className="breadcrumb">
					<li className="breadcrumb-item">
						<Link to={{ pathname: `/${lang}/inicio` }}>
							{loc("NAVBAR-INICIO")}
						</Link>
					</li>
					<li className="breadcrumb-item active" aria-current="page">
						{loc("NAVBAR-RESOLUCIONES")}
					</li>
				</ol>
			</nav>

			<main>
				<div className="col-12 intsection">
                    <div className="col-12 acordeon-container">
                        <div className="col-12 dflex acordeon">
                            <h2
                                className={`titulo acordeon-trigger ${estadoAcordeones[0] ? "abierto" : "cerrado"}`}
                                onClick={() => toggleAcordeon(0)}
                            >
                                {loc("RESOLUCIONES-TITULO1")}
                            </h2>
                            {estadoAcordeones[0] && (
                                <>
									<ResolucionesListing
										data={normativasData}
										tipo={"resolucionesFPPDI"}
									/>
								</>
                            )}							
                        </div>

						<div className="col-12 dflex acordeon">
                            <h2
                                className={`titulo acordeon-trigger ${estadoAcordeones[1] ? "abierto" : "cerrado"}`}
                                onClick={() => toggleAcordeon(1)}
                            >
                                {loc("RESOLUCIONES-TITULO2")}
                            </h2>
                            {estadoAcordeones[1] && (
                                <>
									<ResolucionesListing
										data={normativasData}
										tipo={"resolucionesFPESTU"}
									/>
								</>
                            )}
                        </div>

                        <div className="col-12 dflex acordeon">
                            <h2
                                className={`titulo acordeon-trigger ${estadoAcordeones[2] ? "abierto" : "cerrado"}`}
                                onClick={() => toggleAcordeon(2)}
                            >
                                {loc("RESOLUCIONES-TITULO3")}
                            </h2>
                            {estadoAcordeones[2] && (
                                <ResolucionesListing
								data={normativasData}
								tipo={"resolucionesBA"}
							/>
                            )}
                        </div>
                    </div>
                </div>
			</main>
		</>
	);
};
