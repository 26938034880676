import React, { useContext, useEffect, useState } from "react";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";

//IMG
import logo from "../assets/img/logo.png";
import logouja from "../assets/img/logo-uja.png";
import rrss1 from "../assets/img/rrss-01.svg";
import rrss2 from "../assets/img/rrss-02.svg";
import rrss3 from "../assets/img/rrss-03.svg";
import rrss4 from "../assets/img/rrss-04.svg";
import rrss5 from "../assets/img/rrss-05.svg";
import accept2 from "../assets/img/accept2.svg";
import noaccept2 from "../assets/img/noaccept2.svg";

import Navbar from "./Navbar.js";

//CONTEXT
import ConexContext from "../context/ConexContext";

import Cookies from "js-cookie";

const Layout = () => {
	const { lang, seccion } = useParams(); //parametros de la url
	const navigate = useNavigate();
	const { setLocale, setCookie, loc, cookie } = useContext(ConexContext); //contexto
	const [showModalCookie, setShowModalCookie] = useState(true);
	const [configurarCookie, setconfigurarCookie] = useState(false);
	const [Cookieterceros, setCookieterceros] = useState(false);
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	/**
	 * Cambia el idioma de la página
	 */
	useEffect(() => {
		setLocale(lang);
	}, [lang, setLocale]);

	/**
	 * Si hay idioma y seccion, pero el idioma no es correcto, redirige con el idioma correcto
	 * Si hay idioma y no hay seccion, el usuario quiere acceder a una pagina sin especificar idioma. Redirige por defecto con español
	 */
	useEffect(() => {
		if (lang && seccion && lang.length > 2 ) {
			navigate(`/es` + (seccion ? `/${seccion}` : ""), { replace: true });
		}
		if (lang && !seccion && lang.length > 2 ) {
			navigate(`/es` + (lang ? `/${lang}` : ""), { replace: true });
		}
	}, []);

	/**
	 * Comprueba si se ha aceptado o rechazado las cookies
	 */
	useEffect(() => {
		setCookie(Cookies.get("cookieConsent") === "true" || false);
		setShowModalCookie(Cookies.get("cookieConsent") !== "true");
	}, [setCookie]);

	const closeMenu = () => {
		setIsMenuOpen(false);
	};

	return cookie === undefined ? null : ( //si no se ha aceptado o rechazado las cookies no se muestra nada)
		<>
			{configurarCookie && (
				<div className="col-12 panelcookies">
					<div className="col-12 contpanelcookies">
						<div className="col-12 conborde">
							<div className="col-12">
								<h3>Gestiona las cookies</h3>
								<p>
									Continúa navegando, aceptando nuestra{" "}
									<Link to={{ pathname: `/${lang}/cookies` }}>
										Política de cookies
									</Link>{" "}
									y los servicios de terceros.
								</p>
							</div>
							<div className="col-12 separador">
								<div className="col-12 contseparador">
									<hr />
								</div>
							</div>
							<div className="col-12 centermid">
								<h3>Cookies de terceros:</h3>
								<div className="col-12 cookiesacept">
									<div className="col-6">
										<span>YouTube</span>
									</div>
									<div className="col-6">
										<button
											className="aceptar"
											alt="aceptar"
											onClick={() => {
												setCookieterceros(true);
											}}
										>
											Aceptar
										</button>
										<button
											className="noaceptar"
											alt="rechazar"
											onClick={() => {
												setCookieterceros(false);
											}}
										>
											Rechazar
										</button>
										{Cookieterceros === true ? (
											<img src={accept2} alt="aceptado" className="accept2" />
										) : (
											<img
												src={noaccept2}
												alt="no aceptado"
												className="accept2"
											/>
										)}
									</div>
								</div>
							</div>
							<div className="col-12 separador">
								<div className="col-12 contseparador">
									<hr />
								</div>
							</div>
							<div className="col-12 cookiesacept fixmg2">
								<button
									alt="Configurar"
									onClick={() => {
										setconfigurarCookie(false);
									}}
								>
									Guardar configuración
								</button>
							</div>
						</div>
					</div>
				</div>
			)}

			{showModalCookie && (
				<div className="col-12 cookies">
					<h3>
						<span>
							Este sitio web usa cookies y te permite controlar las que deseas
							activar
						</span>
					</h3>
					<button
						className="aceptar"
						alt="aceptar"
						onClick={() => {
							setCookie(true);
							setShowModalCookie(false);
							Cookies.set("cookieConsent", true);
						}}
					>
						Aceptar
					</button>
					<button
						className="noaceptar"
						alt="rechazar"
						onClick={() => {
							setCookie(false);
							setShowModalCookie(false);
							Cookies.set("cookieConsent", false);
						}}
					>
						Rechazar
					</button>
					<button
						alt="Configurar"
						className="configurar"
						onClick={() => {
							setconfigurarCookie(true);
						}}
					>
						Configurar
					</button>
				</div>
			)}

			<header className="col-12">
				<nav className="navbar navbar-expand-lg bg-light">
					<div className="container-fluid">
						<div className="lang-switch">
							<ul>
								<li
									className={`${lang === "es" ? "active" : ""}`}
									onClick={(e) => {
										setLocale("es");
										window.location.href = `/es/${
											window.location.pathname.split("/")[2]
										}`;
									}}
								>
									Español
								</li>
								|
								<li
									className={`${lang === "en" ? "active" : ""}`}
									onClick={(e) => {
										setLocale("en");
										window.location.href = `/en/${
											window.location.pathname.split("/")[2]
										}`;
									}}
								>
									English
								</li>
							</ul>
						</div>
						<div className="flex-row__header">
							<Link to={`https://www.ujaen.es/`} target="_blank" className="logo">
								<img
									src={logo}
									alt="logoUJA.Universidad de Jaén. Ir a la página de inicio ujaen.es."
								/>
							</Link>
							<div>
								<button
									id="botonmenu"
									className="navbar-toggler"
									type="button"
									data-bs-target="#navbarSupportedContent"
									aria-controls="navbarSupportedContent"
									aria-expanded={isMenuOpen}
									aria-label="Toggle navigation"
									onClick={() => setIsMenuOpen(!isMenuOpen)}
								>
									<span className="navbar-toggler-icon"></span>
								</button>
								<div
									className={`collapse navbar-collapse ${isMenuOpen ? "show" : ""}`}
									id="navbarSupportedContent"
								>
									<Navbar loc={loc} closeMenu={closeMenu} />
								</div>
							</div>
						</div>
					</div>
				</nav>
			</header>
			<main className="col-12">
				<Outlet />
			</main>
			<footer>
				<div className="cont_footer">
					<div className="col-6">

						<div className="uja-footer__datos-contacto-inner">

							<div className="icon uja-footer__logo-uja">
								<img src={logouja} alt="UJA.Universidad de Jaén." className="logo" />
							</div>
							<div className="uja-footer__vcard vcard">
								{/* <h2 className="oculto">Dirección y enlaces de interés</h2> */}
								<div className="adr">
									<p><span className="street-address">Campus Las Lagunillas s/n</span>. <span className="postal-code">23071</span> - <span className="locality">Jaén</span></p>
								</div>
								<div className="tel"><p><span className="type">Teléfono:</span> <span className="value">
									<Link
										to='tel:34953214222'
										onClick={(e) => {
											window.location.href = "tel:953214222";
											e.preventDefault();
										}}
									>
										953 214 222
									</Link>
								</span>
								</p>
								</div>
								<div className="email"><p>
									<Link
										to='mailto:vicfited@ujaen.es'
										onClick={(e) => {
											window.location.href = "mailto:vicfited@ujaen.es";
											e.preventDefault();
										}}
									>
										vicfited@ujaen.es
									</Link>
								</p>
								</div>
							</div>
						</div>

					</div>

					<div className="col-6 col2footer">
						<ul>
							{/* <li className=""><a className="" href="http://www.ujaen.es/mapa">Mapa</a> |</li> */}
							{/* <li className=""><a className="" href="http://www.ujaen.es/accesibilidad">Accesibilidad</a> |</li> */}
							<li className=""><a className="" href="http://www.ujaen.es/aviso-legal">Aviso Legal</a> |</li>
							<li className=""><a className="" href="http://www.ujaen.es/politica-de-privacidad">Política de privacidad</a> |</li>
							<li className=""><Link to={{ pathname: `/${lang}/cookies` }}>Política de cookies</Link> |</li>
							<li className=""><a className="" href="https://sede.ujaen.es/publico/utilidades/quejas-sugerencias">Quejas y Sugerencias</a> |</li>
							<li className=""><Link to={{ pathname: `/${lang}/mapa_web` }}>Mapa web</Link> |</li>
							<li className=""><Link to={{ pathname: `/${lang}/accesibilidad` }}>Accesibilidad</Link></li>
						</ul>
						<ul className='listarrss'>
							<li>
								<Link
									to='#'
									onClick={(e) => {
										window.location.href = "mailto:info@uja.es";
										e.preventDefault();
									}}
								>
									<img src={rrss1} alt="facebook" />
								</Link>
							</li>
							<li>
								<Link
									to='#'
									onClick={(e) => {
										window.location.href = "http://www.linkedin.com/company/universidad-de-ja-n";
										e.preventDefault();
									}}
								>
									<img src={rrss2} alt="linkedin" />
								</Link>
							</li>
							<li>
								<Link
									to='#'
									onClick={(e) => {
										window.location.href = "http://twitter.com/ujaen";
										e.preventDefault();
									}}
								>
									<img src={rrss3} alt="x" />
								</Link>
							</li>

							<li>
								<Link
									to='#'
									onClick={(e) => {
										window.location.href = "https://instagram.com/universidadjaen/";
										e.preventDefault();
									}}
								>
									<img src={rrss4} alt="instagram" />
								</Link>
							</li>

							<li>
								<Link
									to='#'
									onClick={(e) => {
										window.location.href = "https://www.flickr.com/photos/101992261@N07/albums/";
										e.preventDefault();
									}}
								>
									<img src={rrss5} alt="flicker" />
								</Link>
							</li>



						</ul>
					</div>
				</div>
			</footer>
		</>
	);
};

export default Layout;
