import React, { useState, useEffect, useRef } from "react";
import { useLocation, NavLink, useParams } from "react-router-dom";
import { ReactComponent as ArrowDown } from "../assets/img/arrowdown.svg";
import menuConfig from "../menu-config.json";

import { ReactComponent as IconCfid } from "../assets/img/navbar/cfid.svg";
import { ReactComponent as IconCursosdeverano } from "../assets/img/navbar/cursosdeverano.svg";
import { ReactComponent as IconEmpresas } from "../assets/img/navbar/empresas.svg";
import { ReactComponent as IconEstudiantes } from "../assets/img/navbar/estudiantes.svg";
import { ReactComponent as IconInnovacion } from "../assets/img/navbar/innovacion.svg";
import { ReactComponent as IconNormativa } from "../assets/img/navbar/normativa.svg";
import { ReactComponent as IconPdi } from "../assets/img/navbar/pdi.svg";
import { ReactComponent as IconOrganigrama } from "../assets/img/navbar/organigrama.svg";
import { ReactComponent as IconTecnologia } from "../assets/img/navbar/tecnologia.svg";
import { ReactComponent as IconResoluciones } from "../assets/img/navbar/resoluciones.svg";
import { ReactComponent as IconPtgas } from "../assets/img/navbar/ptgas.svg";
import { ReactComponent as IconGenia } from "../assets/img/navbar/genia.svg";
import { ReactComponent as IconMicrocredenciales } from "../assets/img/navbar/microcredenciales.svg";
import { ReactComponent as IconBecas } from "../assets/img/navbar/becas.svg";
import { ReactComponent as IconTramitacion } from "../assets/img/navbar/tramitacion.svg";
import { ReactComponent as IconEdpuzzle } from "../assets/img/navbar/edpuzzle.svg";

const iconComponents = {
	IconCfid,
	IconCursosdeverano,
	IconEmpresas,
	IconEstudiantes,
	IconInnovacion,
	IconNormativa,
	IconPdi,
	IconOrganigrama,
	IconTecnologia,
	IconResoluciones,
	IconPtgas,
	IconGenia,
	IconMicrocredenciales,
	IconBecas,
	IconTramitacion,
	IconEdpuzzle
};

const Navbar = ({ loc, closeMenu }) => {
	const [openSubmenu, setOpenSubmenu] = useState(null);
	const [menuItems, setMenuItems] = useState([]);
	const location = useLocation();
	const { lang } = useParams();

	const submenuRef = useRef(null);

	useEffect(() => {
		setMenuItems(menuConfig.menuItems);
	}, []);

	useEffect(() => {
		setOpenSubmenu(null);
	}, [location]);

	useEffect(() => {
		const listItems = document.querySelectorAll("li[data-color]");
		listItems.forEach((item) => {
			const color = item.getAttribute("data-color");
			if (color) {
				const svgPaths = item.querySelectorAll(
					"svg path, svg circle, svg rect, svg polygon, svg polyline"
				);
				svgPaths.forEach((path) => {
					path.style.fill = color;
				});
			}
		});
	}, [menuItems]);

	const handleClickOutside = (event) => {
		if (submenuRef.current && !submenuRef.current.contains(event.target)) {
			setOpenSubmenu(null);
		}
	};

	useEffect(() => {

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const handleSubmenuToggle = (index, item) => {
		if (openSubmenu !== null) {
			setOpenSubmenu(null);
		} else {
			setOpenSubmenu(index);
		}
		if (!item.subItems.length) {
			closeMenu();
		}
	};

	const renderIcon = (iconName) => {
		const IconComponent = iconComponents[iconName];
		return IconComponent ? <IconComponent className="menu-icon" /> : null;
	};

	const renderSubItems = (subItems, isOpen) => (
		<ul
			className={`sub-menu ${isOpen ? "open" : ""}`}
		>
			{subItems.map((subItem, index) => (
				<li key={index} onClick={closeMenu} data-color={subItem.color || ""}>
					<NavLink to={`/${lang}${subItem.link}`} className="nav-link">
						{renderIcon(subItem.icon)}
						<div className="sub-menu__content" style={{flexDirection: "column"}}>
							{loc(subItem.title)}
							{subItem.description && (
								<div className="sub-item-description">
									{loc(subItem.description)}
								</div>
							)}
						</div>
					</NavLink>
				</li>
			))}
		</ul>
	);

	const renderMenuItem = (item, index) => {
		const hasSubItems = item.subItems.length > 0;
		const isOpen = openSubmenu === index;

		return (
			<li
				key={index}
				className={`nav-item formabot ${
					hasSubItems && isOpen ? "active-submenu" : ""
				}`}
				onClick={() => handleSubmenuToggle(index, item)}
				ref={isOpen ? submenuRef : null}
			>
				{!hasSubItems ? (
					<NavLink
						to={`/${lang}${item.link}`}
						className="nav-link"
						onClick={closeMenu}
					>
						{loc(item.title)}
					</NavLink>
				) : (
					<div className="nav-link">
						{loc(item.title)}
						{hasSubItems && <ArrowDown className="arrow-icon" />}
					</div>
				)}
				{hasSubItems && renderSubItems(item.subItems, isOpen)}
			</li>
		);
	};

	return (
		<ul className="navbar-nav">
			{menuItems.map((item, index) => renderMenuItem(item, index))}
		</ul>
	);
};

export default Navbar;
