import React, { useContext, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import img3 from "../assets/img/3.png";
import ConexContext from "../context/ConexContext";
import CursoListing from "./CursoListing";

export const PDI = () => {
	const { lang } = useParams(); // parámetros de la URL
	const { loc, peticion } = useContext(ConexContext); // contexto
	const [categoriesMap, setcategoriesMap] = useState(null);
	const [tipos, settipos] = useState(null);

	useEffect(() => {
		//pide las categorias (solo al principio)
		async function categorias_peticion() {
			const pet = await peticion('/categoriasPDI', { method: 'POST', json: {categoria: 'novel'}})
			if (pet.estado) //algún error
				return;
				setcategoriesMap(pet.objeto);
		}
		//pide los tipos (solo al principio)
		async function tipos() {
			const pet = await peticion('/tiposPDI', { method: 'POST' })
			if (pet.estado) //algún error
				return;
				settipos(pet.objeto);
		}

		if (categoriesMap) return
		categorias_peticion()
		tipos()
	}, [categoriesMap, peticion]);

	useEffect(() => {
		document.title = "PDI";
	}, []);

	return (
		<>
			<div className="pdi">
				<div className="col-12 contslider">
					<div className="col-12 info">
						<div className="col-6">
							<img src={img3} alt="PDI" className="sliderimg" />
						</div>
						<div className="col-6">
							<h1>{loc("PDI-TITULO")}</h1>
							<p>{loc("PDI-SUBTITULO")}</p>
						</div>
					</div>
				</div>
			</div>

			<nav aria-label="breadcrumb " className="migapan marron">
				<ol className="breadcrumb">
					<li className="breadcrumb-item">
						<Link to={{ pathname: `/${lang}/inicio` }}>
							{loc("NAVBAR-INICIO")}
						</Link>
					</li>
					<li className="breadcrumb-item active" aria-current="page">
						{loc("NAVBAR-PDI")}
					</li>
				</ol>
			</nav>
			{tipos && categoriesMap &&
			<CursoListing data={{"tipos": tipos, "categorias": categoriesMap, "cursos-pdi": null}} json={true} nombre_CAT={"Categoría"}/>
			}
		</>
	);
};

export default PDI;
