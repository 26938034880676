import React, { useState, useEffect, useContext, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ModalCurso from "./ModalCurso";
import ModalCursoHover from "./ModalCursoHover";
import CartelBuilder from "./CartelBuilder";

import { ReactComponent as IconCalendar } from "../assets/img/calendar.svg";
import { ReactComponent as IconFilter } from "../assets/img/icon-filter.svg";
import { ReactComponent as IconClose } from "../assets/img/close.svg";
import MultiSelectDropdown from "./MultiSelectDropdown";
import RangeSlider from "./RangeSlider";
import Calendario from "../assets/js/Calendario";
import clickOut from "../assets/js/clickOut";
import UJALogo from "../assets/img/logo.png";
import { ReactComponent as IconEmpty } from "../assets/img/icon-empty.svg";
//helpers
import { formatFecha } from "../helpers/formatFecha";

//CONTEXT
import ConexContext from "../context/ConexContext";

const CursoListingMicro = ({ data, json, seccion, nombre_CAT }) => {
	const { peticion } = useContext(ConexContext); //contexto

	// refs
	const divCalendar = useRef(); //ref para ocultar el calendario
	const navigate = useNavigate();

	//cierra el calendario
	clickOut(divCalendar, () => {
		setCalendView("hide");
		setCalendView2("hide");
	});

	const [view, setView] = useState("todas");
	const [selectedCurso, setSelectedCurso] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [hoveredCurso, setHoveredCurso] = useState(null); // Estado para el hover
	const [isHoveredModalOpen, setIsHoveredModalOpen] = useState(false); // Estado del modal de hover
	const [show, setShow] = useState({ show: false });
	const [searchTerm, setSearchTerm] = useState("");
	const [pdiView, setPdiView] = useState(data["tipos"][0]["nombre"]);
	const [filteredCursosAux, setfilteredCursosAux] = useState(null);
	const [maximo, setmaximo] = useState(null);
	const [values, setvalues] = useState([0, maximo]); // Valores ECTS
	const [preciomaximo, setpreciomaximo] = useState(null);
	const [precio, setprecio] = useState([0, preciomaximo]); // Valores PRECIO
	const [filtros, setfiltros] = useState(false);
	const [selectedOptionsModalidad, setselectedOptionsModalidad] = useState([]);
	const [OptionsModalidad, setOptionsModalidad] = useState(null);
	const [selectedOptionsRamaC, setselectedOptionsRamaC] = useState([]);
	const [OptionsRamaC, setOptionsRamaC] = useState(null);
	const [selectedOptionsTipoEn, setselectedOptionsTipoEn] = useState([]);
	const [OptionsTipoEn, setOptionsTipoEn] = useState(null);
	const [Cargando, setCargando] = useState(true);
	const [NoResults, setNoResults] = useState(false);
	const [Esfiltrada, setEsfiltrada] = useState(false);
	const [isOverflowHidden, setIsOverflowHidden] = useState(true); // Estado para manejar la clase 'overflow-hidden'
	const [calendView, setCalendView] = useState("hide"); //mostrar calendario para fecha inicio
	const [calendView2, setCalendView2] = useState("hide"); //mostrar calendario para fecha final
	const [fechaIni, setFechaIni] = useState(null); //fecha inicio (restar un mes)
	let fechaIniAux = null; //fecha inicio (restar un mes)
	const [fechaFin, setFechaFin] = useState(null); //fecha fin (hoy)
	let fechaFinAux = null; //fecha fin (hoy)

	const limpiarFiltros = async () => {
		if (selectedOptionsModalidad.length > 0) setselectedOptionsModalidad([]);
		if (selectedOptionsRamaC.length > 0) setselectedOptionsRamaC([]);
		if (selectedOptionsTipoEn.length > 0) setselectedOptionsTipoEn([]);
		if (values[0] !== 0 || values[1] !== maximo) setvalues([0, maximo]);
		if (precio[0] !== 0 || precio[1] !== preciomaximo) setprecio([0, preciomaximo]);
		if (formatDate(fechaIni) !== formatDate(fechaIniAux)) setFechaIni(null)
		if (formatDate(fechaFin) !== formatDate(fechaFinAux)) setFechaFin(null)
		setfiltros(false);
		setCargando(true);
		if (json) {
			const pet = await peticion("/busquedaPDI", {
				method: "POST",
				json: {
					tipoEnsenanza: [],
					modalidad: [],
					ramaconocimiento: [],
					tipo: "todas",
					categoria: "todas",
					busqueda: searchTerm.toLowerCase(),
					horas: [0, maximo],
				},
			});
			if (pet.estado) {
				// Manejar error si es necesario
				return;
			}
			setEsfiltrada(false);
			setfilteredCursosAux(pet.objeto);
			if (pet.objeto.length === 0) {
				setNoResults(true);
			} else {
				setNoResults(false);
			}
			setCargando(false);
		} else {
			const pet = await peticion("/busqueda", {
				method: "POST",
				json: {
					tipoEnsenanza: [],
					modalidad: [],
					ramaconocimiento: [],
					tipo: "MC",
					categoria: "todas",
					busqueda: searchTerm.toLowerCase(),
					horas: [0, maximo],
					precio: [0, preciomaximo],
					fechas: [null, null]
				},
			});
			if (pet.estado) {
				// Manejar error si es necesario
				return;
			}
			setEsfiltrada(false);
			setfilteredCursosAux(pet.objeto);
			if (pet.objeto.length === 0) {
				setNoResults(true);
			} else {
				setNoResults(false);
			}
			setCargando(false);
		}
		
	};

	const formatDate = (date) => {
		// Crear un nuevo objeto Date a partir de la fecha de entrada
		const d = new Date(date);
	
		// Obtener el año, mes y día
		const year = d.getFullYear();
		const month = String(d.getMonth() + 1).padStart(2, '0'); // Los meses van de 0 (enero) a 11 (diciembre)
		const day = String(d.getDate()).padStart(2, '0');
	
		// Formatear la fecha en formato YYYY-MM-DD
		return `${year}-${month}-${day}`;
	};

	useEffect(() => {
		//pide los filtros (solo al principio)
		async function filtros_peticion() {
			peticion(`/modalidades`, {
				method: "POST",
				json: {
					ventana: { nombre: seccion, json: json },
				},
			}).then(async (res) => {
				if (res?.estado ?? true) return;
				setOptionsModalidad(res.objeto);
			});
			peticion(`/ramaconocimientos`, {
				method: "POST",
				json: {
					ventana: { nombre: seccion, json: json },
				},
			}).then(async (res) => {
				if (res?.estado ?? true) return;
				setOptionsRamaC(res.objeto);
			});
			setOptionsTipoEn([{label: 'Microcredencial universitaria', value: 'MC'}]);
			peticion(`/maximo`, {
				method: "POST",
				json: {
					ventana: { nombre: seccion, json: json },
				},
			}).then(async (res) => {
				if (res?.estado ?? true) return;
				setmaximo(res.objeto[0]["creditos"]);
				setvalues([0, res.objeto[0]["creditos"]]);
				setpreciomaximo(res.objeto[0]["precio_matricula"]);
				setprecio([0, res.objeto[0]["precio_matricula"]])
			});
		}
		if (OptionsModalidad && OptionsRamaC && OptionsTipoEn) return;
		filtros_peticion();
		// eslint-disable-next-line
	}, [peticion]);

	useEffect(() => {
		let timeoutId;
		if (!filtros) {
			// Si filtros es false, activa inmediatamente overflow-hidden
			setIsOverflowHidden(true);
		} else {
			// Si filtros es true, Retrasa la activación de overflow-hidden por 0.650 segundos
			timeoutId = setTimeout(() => {
				setIsOverflowHidden(false);
			}, 400);
		}
		// Limpia el timeout si el componente se desmonta o si 'filtros' cambia a true
		return () => clearTimeout(timeoutId);
	}, [filtros]);

	useEffect(() => {
		if (
			// CANTIDAD MINIMA DE LETRAS(4) PARA BUSQUEDA
			searchTerm.length > 3 ||
			// PARA POR DEFECTO
			filteredCursosAux === null ||
			// ALGUN TIPO DE FILTRO TIPOEN, MODALIDAD, RAMAC, VALORES SLIDER
			selectedOptionsTipoEn.length > 0 ||
			selectedOptionsModalidad.length > 0 ||
			selectedOptionsRamaC.length > 0 ||
			(values[0] !== -1 && filtros === true)||
			(values[1] !== (maximo)+1 && filtros === true) ||
			(precio[0] !== -1 && filtros === true) ||
			(precio[1] !== (preciomaximo)+1 && filtros === true) ||
			formatDate(fechaFin) !== formatDate(fechaFinAux) ||
			formatDate(fechaIni) !== formatDate(fechaIniAux) ||
			// HACER PETICION EN CASO DE BORRAR EL TEXTO DEL INPUT Y LOS ULTIMOS DATOS SON FILTRADOS
			(searchTerm.length === 0 &&
				Esfiltrada === true &&
				// LOS FILTROS ES TRUE
				filtros === true)
		) {
			setCargando(true);
			// Define un identificador de timeout para manejar el debounce
			const handler = setTimeout(() => {
				// Función asíncrona que realiza la petición
				async function busqueda_peticion() {
					if (json) {
						const pet = await peticion("/busquedaPDI", {
							method: "POST",
							json: {
								tipoEnsenanza: selectedOptionsTipoEn,
								modalidad: selectedOptionsModalidad,
								ramaconocimiento: selectedOptionsRamaC,
								tipo: "todas",
								categoria: "todas",
								busqueda: searchTerm.toLowerCase(),
								horas: values,
							},
						});
						if (pet.estado) {
							// Manejar error si es necesario
							return;
						}
						if (
							searchTerm.length > 0 ||
							selectedOptionsTipoEn.length > 0 ||
							selectedOptionsModalidad.length > 0 ||
							selectedOptionsRamaC.length > 0
						) {
							setEsfiltrada(true);
						} else {
							setEsfiltrada(false);
						}
						setfilteredCursosAux(pet.objeto);
						if (pet.objeto.length === 0) {
							setNoResults(true);
						} else {
							setNoResults(false);
						}
						setCargando(false);
					} else {
						const pet = await peticion("/busqueda", {
							method: "POST",
							json: {
								tipoEnsenanza: selectedOptionsTipoEn,
								modalidad: selectedOptionsModalidad,
								ramaconocimiento: selectedOptionsRamaC,
								tipo: "MC",
								categoria: "todas",
								busqueda: searchTerm.toLowerCase(),
								horas: values,
								precio: precio,
								fechas: [fechaIni, fechaFin]
							},
						});
						if (pet.estado) {
							// Manejar error si es necesario
							return;
						}
						if (
							searchTerm.length > 0 ||
							selectedOptionsTipoEn.length > 0 ||
							selectedOptionsModalidad.length > 0 ||
							selectedOptionsRamaC.length > 0 ||
							fechaIni !== null ||
							fechaFin !== null
						) {
							setEsfiltrada(true);
						} else {
							setEsfiltrada(false);
						}
						if (formatDate(fechaFin) !== formatDate(fechaFinAux)){
							// eslint-disable-next-line
							fechaFinAux=formatDate(fechaFin)
						}
						if (formatDate(fechaIni) !== formatDate(fechaIniAux)){
							// eslint-disable-next-line
							fechaIniAux=formatDate(fechaIni)
						}
						setfilteredCursosAux(pet.objeto);
						if (pet.objeto.length === 0) {
							setNoResults(true);
						} else {
							setNoResults(false);
						}
						setCargando(false);
					}
				}
				// Llama a la función de petición
				if (values[1] > 0) {
					busqueda_peticion();
					setView("todas");
					setPdiView("MC");
				}
			}, 600); // 500 ms de retraso
			// Limpia el timeout si searchTerm o values cambian antes de que se complete el timeout
			return () => {
				clearTimeout(handler);
			};
		}
		// eslint-disable-next-line
	}, [
		json,
		searchTerm,
		precio,
		fechaIni,
		fechaFin,
		values,
		// pdiView,
		selectedOptionsTipoEn,
		selectedOptionsModalidad,
		selectedOptionsRamaC,
		peticion,
	]); // El efecto se vuelve a ejecutar cuando searchTerm, values o pdiView cambian

	const handleSearchChange = (event) => {
		if (event.target.value.length > 3) {
			setView("todas");
			setPdiView("MC");
			setfiltros(true);
		}
		setSearchTerm(event.target.value);
	};

	const handleCategoryClick = async (categoria) => {
		setCargando(true);
		setSearchTerm("");
		setfiltros(false);
		setView(categoria.nombre);
		if (json) {
			const pet = await peticion("/busquedaPDI", {
				method: "POST",
				json: {
					tipoEnsenanza: [],
					modalidad: [],
					ramaconocimiento: [],
					tipo: pdiView,
					categoria: categoria.nombre,
					busqueda: searchTerm.toLowerCase(),
					horas: values,
				},
			});
			if (pet.estado) {
				// Manejar error si es necesario
				return;
			}
			setEsfiltrada(false);
			setfilteredCursosAux(pet.objeto);
			if (pet.objeto.length === 0) {
				setNoResults(true);
			} else {
				setNoResults(false);
			}
			if (selectedOptionsModalidad.length > 0) setselectedOptionsModalidad([]);
			if (selectedOptionsRamaC.length > 0) setselectedOptionsRamaC([]);
			if (selectedOptionsTipoEn.length > 0) setselectedOptionsTipoEn([]);
			setCargando(false);
		} else {
			const pet = await peticion("/busqueda", {
				method: "POST",
				json: {
					tipoEnsenanza: [],
					modalidad: [],
					ramaconocimiento: [],
					tipo: pdiView,
					categoria: categoria.nombre,
					busqueda: searchTerm.toLowerCase(),
					horas: values,
					precio: precio,
					fechas: [fechaIni, fechaFin]
				},
			});
			if (pet.estado) {
				// Manejar error si es necesario
				return;
			}
			setEsfiltrada(false);
			setfilteredCursosAux(pet.objeto);
			if (pet.objeto.length === 0) {
				setNoResults(true);
			} else {
				setNoResults(false);
			}
			if (selectedOptionsModalidad.length > 0) setselectedOptionsModalidad([]);
			if (selectedOptionsRamaC.length > 0) setselectedOptionsRamaC([]);
			if (selectedOptionsTipoEn.length > 0) setselectedOptionsTipoEn([]);
			setCargando(false);
		}
	};

	// const handleTipoClick = async (Tipo) => {
	// 	setCargando(true);
	// 	setSearchTerm("");
	// 	setfiltros(false);
	// 	setPdiView(Tipo);
	// 	if (json) {
	// 		const pet = await peticion("/busquedaPDI", {
	// 			method: "POST",
	// 			json: {
	// 				tipoEnsenanza: [],
	// 				modalidad: [],
	// 				ramaconocimiento: [],
	// 				tipo: Tipo,
	// 				categoria: view,
	// 				busqueda: searchTerm.toLowerCase(),
	// 				horas: values,
	// 			},
	// 		});
	// 		if (pet.estado) {
	// 			// Manejar error si es necesario
	// 			return;
	// 		}
	// 		setEsfiltrada(false);
	// 		setfilteredCursosAux(pet.objeto);
	// 		if (pet.objeto.length === 0) {
	// 			setNoResults(true);
	// 		} else {
	// 			setNoResults(false);
	// 		}
	// 		if (selectedOptionsModalidad.length > 0) setselectedOptionsModalidad([]);
	// 		if (selectedOptionsRamaC.length > 0) setselectedOptionsRamaC([]);
	// 		if (selectedOptionsTipoEn.length > 0) setselectedOptionsTipoEn([]);
	// 		setCargando(false);
	// 	} else {
	// 		const pet = await peticion("/busqueda", {
	// 			method: "POST",
	// 			json: {
	// 				tipoEnsenanza: [],
	// 				modalidad: [],
	// 				ramaconocimiento: [],
	// 				tipo: Tipo,
	// 				categoria: view,
	// 				busqueda: searchTerm.toLowerCase(),
	// 				horas: values,
	// 				precio: precio,
	// 				fechas: [fechaIni, fechaFin]
	// 			},
	// 		});
	// 		if (pet.estado) {
	// 			// Manejar error si es necesario
	// 			return;
	// 		}
	// 		setEsfiltrada(false);
	// 		setfilteredCursosAux(pet.objeto);
	// 		if (pet.objeto.length === 0) {
	// 			setNoResults(true);
	// 		} else {
	// 			setNoResults(false);
	// 		}
	// 		if (selectedOptionsModalidad.length > 0) setselectedOptionsModalidad([]);
	// 		if (selectedOptionsRamaC.length > 0) setselectedOptionsRamaC([]);
	// 		if (selectedOptionsTipoEn.length > 0) setselectedOptionsTipoEn([]);
	// 		setCargando(false);
	// 	}
	// };

	const location = useLocation();

	const [params, setParams] = useState({
		searchFor: new URLSearchParams(location.search).get('curso')
	});

	const getCursoFromTypeId = (tipo, id) => {
		if (!tipo || !id) return null;
		const curso = filteredCursosAux.find((curso) => curso.tipo === tipo && curso.id === id);
		return curso;
	};
	
	useEffect(() => {
		if (params.searchFor && !Cargando ) {
			handlePermalink();
		}
	}, [Cargando]);
	
	useEffect(() => {
		if (params.searchFor && selectedCurso && filteredCursosAux) {
			const [tipo, id] = params.searchFor.split("-");
			setSelectedCurso(getCursoFromTypeId(tipo, id));
			setIsHoveredModalOpen(true);
			setParams({ searchFor: null });
		}
	}, [selectedCurso, filteredCursosAux, isModalOpen]);

	const handlePermalink = () => {
		const [tipo, id] = params.searchFor.split("-");
		setSelectedCurso(getCursoFromTypeId(tipo, id));
		setIsModalOpen(true);
		setShow({ show: true });
	};

	const handleCardClick = (curso) => {
		if (curso.estado === "Disponible en breve") {
			setIsModalOpen(false);
		} else {
			navigate(`${location.pathname}?curso=${curso.tipo}-${curso.id}`);
			setSelectedCurso(getCursoFromTypeId(curso.tipo, curso.id));
			setIsModalOpen(true);
		}
	};
	
	const closeModal = () => {
		setIsModalOpen(false);
		setSelectedCurso(null);
	};

	const handleMouseEnter = (curso) => {
		if (curso.estado === "Cerrado") {
			return;
		} else {
			setHoveredCurso(curso);
			setIsHoveredModalOpen(true);
		}
	};

	const handleMouseLeave = () => {
		setIsHoveredModalOpen(false);
		setHoveredCurso(null);
	};

	useEffect(() => {
		// Efecto para abrir y cerrar el menú de filtros
		const element = document.querySelector(
			".course-browser__filters--container"
		);
		if (filtros) {
			element.style.maxHeight = `${element.scrollHeight}px`;
		} else {
			element.style.maxHeight = "0px";
		}
	}, [filtros]);

	const renderCursosForCategory = (categoria) => {
		let cursosForCategory;
		cursosForCategory = filteredCursosAux.filter(
			(curso) =>
				curso.categorias.includes(categoria.nombre) &&
				(pdiView === "todas" || pdiView.includes(curso.tipo))
		);
		return (
			<div key={categoria.nombre}>
				{cursosForCategory.length > 0 && !Esfiltrada && (
					<p className="course-browser__recount">
						<span>{categoria.label}:</span> {cursosForCategory.length} {cursosForCategory.length === 1 ? 'curso' : 'cursos'}
						{searchTerm.length > 3 && (
							<>
								{" "}
								que coinciden con "<span>{searchTerm}</span>"
							</>
						)}
					</p>
				)}
				<div className="card-wrapper--vertical">
					{cursosForCategory.map((curso, index) => (
						<div
							className="card-wrapper__card"
							key={curso.id+index}
							slide={index}
							onClick={() => {
								setShow({ show: true });
								handleCardClick(curso);
							}}
							onMouseEnter={() => handleMouseEnter(curso)}
							onMouseLeave={handleMouseLeave}
						>
							<div className="card-wrapper__content">
								<CartelBuilder curso={curso} json={json} tipos={data["tipos"]} />
							</div>
							{isHoveredModalOpen &&
								hoveredCurso &&
								hoveredCurso.id === curso.id && (
									<ModalCursoHover
										cursosData={data}
										curso={hoveredCurso}
										onClose={() => setIsHoveredModalOpen(false)}
										json={json}
									/>
								)}
						</div>
					))}
				</div>
			</div>
		);
	};

	const renderCursos_Aux = (tipo) => {
		let cursosForTipo;
		cursosForTipo = filteredCursosAux.filter(
			(curso) =>
				tipo.nombre.includes(curso.tipo) &&
				(pdiView === "todas" || pdiView.includes(tipo.nombre))
		);
		return (
			<div key={tipo.nombre}>
				{cursosForTipo.length > 0 && (
					<p className="course-browser__recount">
						<span>{tipo.label}:</span> {cursosForTipo.length} {cursosForTipo.length === 1 ? 'curso' : 'cursos'}
					</p>
				)}
				<div className="card-wrapper--vertical">
					{cursosForTipo.map((curso, index) => (
						<div
							className="card-wrapper__card"
							key={curso.id+index}
							slide={index}
							onClick={() => {
								setShow({ show: true });
								handleCardClick(curso);
							}}
							onMouseEnter={() => handleMouseEnter(curso)}
							onMouseLeave={handleMouseLeave}
						>
							<div className="card-wrapper__content">
								<CartelBuilder curso={curso} json={json} tipos={data["tipos"]} />
							</div>
							{isHoveredModalOpen &&
								hoveredCurso &&
								hoveredCurso.id === curso.id && (
									<ModalCursoHover
										cursosData={data}
										curso={hoveredCurso}
										onClose={() => setIsHoveredModalOpen(false)}
										json={json}
									/>
								)}
						</div>
					))}
				</div>
			</div>
		);
	}

	return (
		<main>
			<div className="intsection">
				<div className="course-browser__header">
					{/* <ul className="course-type-selector mobile-hidden-768">
						{data["tipos"].map((tipo) => (
							<li key={tipo.nombre}>
								<button
									className={pdiView === tipo.nombre ? "active" : ""}
									onClick={() => handleTipoClick(tipo.nombre)}
								>
									{tipo.label}
								</button>
							</li>
						))}
					</ul> */}
					<div className="course-browser__searchmenu">
						<div className="course-browser__searchbar">
							<input
								type="text"
								placeholder="Buscar cursos..."
								value={searchTerm}
								onChange={handleSearchChange}
							/>
							<IconFilter
								className="mobile-hidden-768"
								alt="Filtros"
								onClick={() => {
									setfiltros(!filtros);
								}}
							/>
						</div>
					</div>
					<div
						className={`mobile-hidden-768 course-browser__filters--container ${
							filtros
								? isOverflowHidden
									? "visible-drop overflow-hidden"
									: "visible-drop"
								: isOverflowHidden
								? "overflow-hidden"
								: ""
						}`}
					>
						<div className="horizontal-divider"></div>
						<div className="course-browser__filters--content">
							<div className="course-browser__filters--wrap">
								<div className="course-browser__filters--item">
									<MultiSelectDropdown
										options={OptionsModalidad}
										label="Modalidad:"
										selectedOptions={selectedOptionsModalidad}
										setSelectedOptions={setselectedOptionsModalidad}
									/>
								</div>
								<div className="course-browser__filters--item">
									<MultiSelectDropdown
										options={OptionsRamaC}
										label="Rama conocimiento:"
										selectedOptions={selectedOptionsRamaC}
										setSelectedOptions={setselectedOptionsRamaC}
										// auxiliar={setView}
									/>
								</div>
								{/* <div className="course-browser__filters--item">
									<MultiSelectDropdown
										options={OptionsTipoEn}
										label="Tipo enseñanza:"
										selectedOptions={selectedOptionsTipoEn}
										setSelectedOptions={setselectedOptionsTipoEn}
									/>
								</div> */}
								<div className="course-browser__filters--item">
									{maximo && (
										<RangeSlider
											min={0}
											max={maximo}
											step={1}
											values={values}
											setvalues={setvalues}
											label="ECTS:"
											sufijo=""
										/>
									)}
								</div>
								{!json && 
								<div className="course-browser__filters--item">
									{preciomaximo && (
										<RangeSlider
											min={0}
											max={preciomaximo}
											step={1}
											values={precio}
											setvalues={setprecio}
											label="Precio:"
											sufijo="€"
										/>
									)}
								</div>
								}
								{!json && 
								<div className="course-browser__filters--item">
									<label>Microcredenciales que se imparten entre (Inicio - Fin):</label>
									<div className="course-browser__filters--calendar" ref={divCalendar}>
										<button
											className="start-date"
											onClick={() => {
												setCalendView2("hide");
												setCalendView("view");
											}}>
											<IconCalendar />
											<span>{fechaIni ? formatFecha(fechaIni, "jav", "esp") : ""}</span>
										</button>
										<Calendario
											date={fechaIni ? fechaIni : new Date(new Date().getTime())}
											setDate={setFechaIni}
											calendView={calendView}
											setCalendView={setCalendView}
										/>

										<span>-</span>
										<button
											className="end-date"
											onClick={() => {
												setCalendView("hide");
												setCalendView2("view");
											}}>
											<span>{fechaFin ? formatFecha(fechaFin, "jav", "esp") : ""}</span>
										</button>
										<Calendario
											date={fechaFin ? fechaFin : new Date(new Date().getTime())}
											setDate={setFechaFin}
											calendView={calendView2}
											setCalendView={setCalendView2}
											minimumDate={{
												day: fechaIni?.getDate(),
												month: fechaIni?.getMonth() + 1,
												year: fechaIni?.getFullYear(),
											}}
										/>
									</div>
								</div>
								}
							</div>
						</div>
					</div>
				</div>

				<div className="course-browser">
					<div className="course-browser__navigation">
						{data["categorias"].map((categoria, i) => (
							<div
								key={i}
								onClick={() => handleCategoryClick(categoria)}
								className={`course-browser__navigation-item course-browser__tooltip ${
									view === categoria.nombre ? "active" : ""
								}`}
							>
								<img
									src={"/assets/img/" + categoria.icono}
									alt={categoria.label}
								/>
								<div className="course-browser__tooltip__text">
									{categoria.label}
								</div>
							</div>
						))}
					</div>

					<div className="divider--vertical mobile-hidden-768"></div>

					{!Cargando && (
						<div className="course-browser__content">
							{Esfiltrada ? (
								<>
									<div className="category-info">
										<div>
											<h2 className="category-info__filters--title">
												Resultados de búsqueda en Microcredenciales Universitarias
											</h2>
										</div>
										{(selectedOptionsModalidad.length > 0 ||
											selectedOptionsRamaC.length > 0 ||
											selectedOptionsTipoEn.length > 0 ||
											fechaIni !== null ||
											fechaFin !== null) && (
											<div className="flex-vertical">
												<span
													className={
														selectedOptionsModalidad.length > 0 ||
														selectedOptionsRamaC.length > 0 ||
														selectedOptionsTipoEn.length > 0 ||
														fechaIni !== null ||
														fechaFin !== null
															? "clear-filters"
															: "clear-filters disabled"
													}
													onClick={() => {
														limpiarFiltros();
													}}
												>
													Limpiar filtros
													<IconClose className="clear-filters" alt="Cerrar" />
												</span>
												<div className="flex-horizontal space-between">
													<div className="category-info__filters--list">
														Filtros activos{": "}
														<span>
															{fechaIni !== null && (
																<>Fecha de Inicio: {formatDate(fechaIni)}"</>
															)}
															{fechaFin !== null && (
																<>Fecha de Fin: {formatDate(fechaFin)}"</>
															)}
															{selectedOptionsModalidad.length > 0 && (
																<>Modalidad: "</>
															)}
															{selectedOptionsModalidad.length > 0 &&
																selectedOptionsModalidad
																	.map((option) => option.label)
																	.join(", ")}
															{selectedOptionsModalidad.length > 0 && <>" </>}
															{selectedOptionsRamaC.length > 0 && (
																<> Rama de conocimiento: "</>
															)}
															{selectedOptionsRamaC.length > 0 &&
																selectedOptionsRamaC
																	.map((option) => option.label)
																	.join(", ")}
															{selectedOptionsRamaC.length > 0 && <>" </>}
															{selectedOptionsTipoEn.length > 0 && (
																<> Tipo de enseñanza: "</>
															)}
															{selectedOptionsTipoEn.length > 0 &&
																selectedOptionsTipoEn
																	.map((option) => option.label)
																	.join(", ")}
															{selectedOptionsTipoEn.length > 0 && <>" </>}
														</span>
													</div>
												</div>
											</div>
										)}
										{Esfiltrada && searchTerm.length > 0 && (
											<p>
												Resultados que coinciden con la búsqueda "
												<span>{searchTerm}</span>": {filteredCursosAux.length}
											</p>
										)}
										{Esfiltrada && searchTerm.length === 0 && (
											<p>
												Resultados: {filteredCursosAux.length}
											</p>
										)}
										<img
											src={"../assets/img/busqueda_fondo2.jpg"}
											alt=""
										/>
										<div className="gradient-background--45deg"></div>
									</div>

								</>
							) : (
								<div className="category-info">
									<div>
										<h2>
											{
												data["tipos"].find((tipo) => tipo.nombre === pdiView)
													?.label
											}
										</h2>
										<p dangerouslySetInnerHTML={{ __html: data["tipos"].find((tipo) => tipo.nombre === pdiView)
													?.descripcion }}>
										</p>
									</div>
									<h3>
										{nombre_CAT}:{" "}
										<span>
											{
												data["categorias"].find(
													(categoria) => categoria.nombre === view
												)?.label
											}
										</span>
										<> ({filteredCursosAux && filteredCursosAux.length})</>
									</h3>
									<img
										src={
											data["tipos"].find((tipo) => tipo.nombre === pdiView)
												?.fondo
										}
										alt=""
									/>
									<div className="gradient-background--45deg"></div>
								</div>
							)}

							{!Cargando && !Esfiltrada && view !== "todas" && (
								<p className="course-browser__recount">
									<span>
										{
											data["categorias"].find(
												(categoria) => categoria.nombre === view
											)?.label
										}
										:
									</span>{" "}
									{filteredCursosAux && filteredCursosAux.length} {filteredCursosAux && filteredCursosAux.length === 1 ? 'curso' : 'cursos'}
									{searchTerm.length > 3 && (
										<>
											{" "}
											que coinciden con "<span>{searchTerm}</span>"
										</>
									)}
								</p>
							)}

							<div>
								<>
									{NoResults && !Cargando && (
										<div className="card-wrapper__empty-results">
											<IconEmpty />
											<span>
												{" "}
												No hemos encontrado resultados para tu búsqueda
											</span>
										</div>
									)}

									{filteredCursosAux && searchTerm.length > 3 && !Cargando ? (
										data["tipos"].filter((tipo) => tipo.nombre !== "todas").map((tipo) => renderCursos_Aux(tipo))
									) : filteredCursosAux && view === "todas" && !Cargando ? (
										!Esfiltrada ? (
											data["categorias"].filter((categoria) => categoria.nombre !== "todas").map((categoria) => renderCursosForCategory(categoria))
										):(
											data["tipos"].filter((tipo) => tipo.nombre !== "todas").map((tipo) => renderCursos_Aux(tipo))
										)
									) : (
										!Cargando &&
										!NoResults && (
											<div className="card-wrapper--vertical">
												{filteredCursosAux &&
													filteredCursosAux.map((curso, index) => (
														<div
															className="card-wrapper__card"
															key={curso.id+index}
															slide={index}
															onClick={() => {
																setShow({ show: true });
																handleCardClick(curso);
															}}
															onMouseEnter={() => handleMouseEnter(curso)}
															onMouseLeave={handleMouseLeave}
														>
															<div className="card-wrapper__content">
																<CartelBuilder curso={curso} json={json} tipos={data["tipos"]} />
															</div>
															{isHoveredModalOpen &&
																hoveredCurso &&
																hoveredCurso.id === curso.id && (
																	<ModalCursoHover
																		cursosData={data}
																		curso={hoveredCurso}
																		onClose={() => setIsHoveredModalOpen(false)}
																		json={json}
																	/>
																)}
														</div>
													))}
											</div>
										)
									)}
								</>
							</div>

							{isModalOpen && (
								<ModalCurso
									show={show.show}
									onHide={() => setShow({ show: false })}
									info={show.curso}
									img={show.img}
									curso={selectedCurso}
									onClose={closeModal}
									cursosData={data}
									json={json}
									nombre_CAT={nombre_CAT}
								/>
							)}
						</div>
					)}
					{Cargando && (
						<div className="course-browser__content">
							<div className="card-wrapper__loading">
								<img className="loading-image" src={UJALogo} alt="Cargando" />
							</div>
						</div>
					)}
				</div>
			</div>
		</main>
	);
};

export default CursoListingMicro;
